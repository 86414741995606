import { FC, useEffect } from 'react'
import styles from './RobotList.module.scss'
import { RobotF } from '@src/logic/contexts/Robots/RobotsFrontend.type'
import { LxSelectInput } from '@src/components/select/selectInput'
 import { AccountF } from '@src/logic/contexts/Accounts/AccountsFrontend.type'
import { Controller, useForm } from 'react-hook-form'
import { useDataProvider, useNotify } from 'react-admin'
import { useSubmitLoader } from '@src/logic/contexts/utils/SubmitContext'
import usePrevious from '@src/hooks/usePrevious.hook'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { cn } from '@src/utils/cn'
import { LxLoadingSpinner } from '@src/components/loader/loadingSpinner'
import modalStyles from '@components/modal/modal.module.scss'
import { useRefresh } from 'react-admin';

interface AssignRobotProps {
  onClose: () => void
  robot: RobotF | null
  accounts: AccountF[]
}

const schema = yup
  .object()
  .shape({
    selectedAccountId: yup.string().required('Selecting an account is required.'),
  })
  .required()


export const AssignRobot: FC<AssignRobotProps> = ({ robot, onClose, accounts }) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const { isFormSubmitting, setIsFormSubmitting } = useSubmitLoader()
  const refresh = useRefresh();

  const {
    reset,
    control,
    watch,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      selectedAccountId: '', // Set the default value
    },
    resolver: yupResolver(schema),
  })

  const onSubmit = async (formData) => {
    if (isFormSubmitting) {
      return
    }

    setIsFormSubmitting(true)

    try {
      const { selectedAccountId } = formData

      if ( !selectedAccountId) {
        notify('Robot or Account not selected', { type: 'error' })
        return
      }

      const assignData = {
        id: robot?.id,
        accountId: selectedAccountId,
        method: 'assignRobot',
      }
      // for adminApp, we will put method as create and customize the call in DataProvider.tsx
      await dataProvider.create('Robot', { data: assignData })

      notify('Robot assigned successfully', { type: 'success' })
      refresh()
    } catch (error) {
      const errorMessage = error.message || 'Something went wrong. Please try again later.'
      notify(errorMessage, { type: 'error' })
    } finally {
      handleDialogClose()
    }
  }

  const selectedAccountId = watch('selectedAccountId')
  const prevSelectedAccountId = usePrevious(selectedAccountId)

  useEffect(() => {
    if (prevSelectedAccountId !== selectedAccountId) {
      clearErrors('selectedAccountId')
    }
  }, [clearErrors, prevSelectedAccountId, selectedAccountId])

  const handleDialogClose = () => {
    reset({ selectedAccountId: '' })
    onClose()
    setIsFormSubmitting(false)
  }

  return (
    <div className={modalStyles.modalMd}>
      <div className={modalStyles.modalContentContainer}>
        {isFormSubmitting && (
          <div className={styles.LoadingOverlay}>
            <LxLoadingSpinner />
          </div> 
        )}

        <div className={modalStyles.dialogHeader}>
          <h3>Assign Robot - {robot?.serial} To Selected Account </h3>
        </div>
 
        <div className={modalStyles.dialogContent}>
          {robot ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                name='selectedAccountId'
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <LxSelectInput
                     value={value}
                    hasError={!!error}
                    onReset={() => reset({selectedAccountId: ''})}
                    onChange={(selected) => {
                      onChange(selected ? selected.id : '')
                    }}
                    options={accounts}
                    placeholder={'Select Account'}
                  />
                )}
              />

              {errors.selectedAccountId && <p>{errors.selectedAccountId.message}</p>}
            </form>
          ) : (
            <p>No robot provided</p>
          )}
        </div>

        <div className={modalStyles.dialogActions}>
          <div className={cn('lxActionButton lxActionButtonDefaultSize')} onClick={handleDialogClose}>
            <span>Cancel</span>
          </div>
          <div className={cn('lxActionButton lxActionButtonFilled lxActionButtonDefaultSize')} onClick={handleSubmit(onSubmit)}>
            <span>Assign the Robot</span>
          </div>
        </div>
      </div>
    </div>
  )
}
