import SubscriptionList from './SubscriptionList.tsx'
// import SubscriptionCreate from './SubscriptionCreate.tsx'
import { SubscriptionListProvider } from '@logic/contexts/Subscriptions/SubscriptionsListsContext.tsx'
import SubscriptionCreate from './CreateOrEdit/SubscriptionCreate.tsx'
import { AccountListProvider } from '@src/logic/contexts/Accounts/AccountsListsContext.tsx'


export default {
  list: <SubscriptionListProvider><SubscriptionList/></SubscriptionListProvider>,
  create: <AccountListProvider><SubscriptionCreate /></AccountListProvider>
}
