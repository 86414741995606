// Intl.supportedValuesOf('timeZone')

export const timeZoneOptions = [
    
    {name: "America/Adak", label: "America/Adak"},
    {name: "America/Anchorage", label: "America/Anchorage"},
    {name: "America/Anguilla", label: "America/Anguilla"},
    {name: "America/Antigua", label: "America/Antigua"},
    {name: "America/Araguaina", label: "America/Araguaina"},
    {name: "America/Argentina/La_Rioja", label: "America/Argentina/La_Rioja"},
    {name: "America/Argentina/Rio_Gallegos", label: "America/Argentina/Rio_Gallegos"},
    {name: "America/Argentina/Salta", label: "America/Argentina/Salta"},
    {name: "America/Argentina/San_Juan", label: "America/Argentina/San_Juan"},
    {name: "America/Argentina/San_Luis", label: "America/Argentina/San_Luis"},
    {name: "America/Argentina/Tucuman", label: "America/Argentina/Tucuman"},
    {name: "America/Argentina/Ushuaia", label: "America/Argentina/Ushuaia"},
    {name: "America/Aruba", label: "America/Aruba"},
    {name: "America/Asuncion", label: "America/Asuncion"},
    {name: "America/Bahia", label: "America/Bahia"},
    {name: "America/Bahia_Banderas", label: "America/Bahia_Banderas"},
    {name: "America/Barbados", label: "America/Barbados"},
    {name: "America/Belem", label: "America/Belem"},
    {name: "America/Belize", label: "America/Belize"},
    {name: "America/Blanc-Sablon", label: "America/Blanc-Sablon"},
    {name: "America/Boa_Vista", label: "America/Boa_Vista"},
    {name: "America/Bogota", label: "America/Bogota"},
    {name: "America/Boise", label: "America/Boise"},
    {name: "America/Buenos_Aires", label: "America/Buenos_Aires"},
    {name: "America/Cambridge_Bay", label: "America/Cambridge_Bay"},
    {name: "America/Campo_Grande", label: "America/Campo_Grande"},
    {name: "America/Cancun", label: "America/Cancun"},
    {name: "America/Caracas", label: "America/Caracas"},
    {name: "America/Catamarca", label: "America/Catamarca"},
    {name: "America/Cayenne", label: "America/Cayenne"},
    {name: "America/Cayman", label: "America/Cayman"},
    {name: "America/Chicago", label: "America/Chicago"},
    {name: "America/Chihuahua", label: "America/Chihuahua"},
    {name: "America/Ciudad_Juarez", label: "America/Ciudad_Juarez"},
    {name: "America/Coral_Harbour", label: "America/Coral_Harbour"},
    {name: "America/Cordoba", label: "America/Cordoba"},
    {name: "America/Costa_Rica", label: "America/Costa_Rica"},
    {name: "America/Creston", label: "America/Creston"},
    {name: "America/Cuiaba", label: "America/Cuiaba"},
    {name: "America/Curacao", label: "America/Curacao"},
    {name: "America/Danmarkshavn", label: "America/Danmarkshavn"},
    {name: "America/Dawson", label: "America/Dawson"},
    {name: "America/Dawson_Creek", label: "America/Dawson_Creek"},
    {name: "America/Denver", label: "America/Denver"},
    {name: "America/Detroit", label: "America/Detroit"},
    {name: "America/Dominica", label: "America/Dominica"},
    {name: "America/Edmonton", label: "America/Edmonton"},
    {name: "America/Eirunepe", label: "America/Eirunepe"},
    {name: "America/El_Salvador", label: "America/El_Salvador"},
    {name: "America/Fort_Nelson", label: "America/Fort_Nelson"},
    {name: "America/Fortaleza", label: "America/Fortaleza"},
    {name: "America/Glace_Bay", label: "America/Glace_Bay"},
    {name: "America/Godthab", label: "America/Godthab"},
    {name: "America/Goose_Bay", label: "America/Goose_Bay"},
    {name: "America/Grand_Turk", label: "America/Grand_Turk"},
    {name: "America/Grenada", label: "America/Grenada"},
    {name: "America/Guadeloupe", label: "America/Guadeloupe"},
    {name: "America/Guatemala", label: "America/Guatemala"},
    {name: "America/Guayaquil", label: "America/Guayaquil"},
    {name: "America/Guyana", label: "America/Guyana"},
    {name: "America/Halifax", label: "America/Halifax"},
    {name: "America/Havana", label: "America/Havana"},
    {name: "America/Hermosillo", label: "America/Hermosillo"},
    {name: "America/Indiana/Knox", label: "America/Indiana/Knox"},
    {name: "America/Indiana/Marengo", label: "America/Indiana/Marengo"},
    {name: "America/Indiana/Petersburg", label: "America/Indiana/Petersburg"},
    {name: "America/Indiana/Tell_City", label: "America/Indiana/Tell_City"},
    {name: "America/Indiana/Vevay", label: "America/Indiana/Vevay"},
    {name: "America/Indiana/Vincennes", label: "America/Indiana/Vincennes"},
    {name: "America/Indiana/Winamac", label: "America/Indiana/Winamac"},
    {name: "America/Indianapolis", label: "America/Indianapolis"},
    {name: "America/Inuvik", label: "America/Inuvik"},
    {name: "America/Iqaluit", label: "America/Iqaluit"},
    {name: "America/Jamaica", label: "America/Jamaica"},
    {name: "America/Jujuy", label: "America/Jujuy"},
    {name: "America/Juneau", label: "America/Juneau"},
    {name: "America/Kentucky/Monticello", label: "America/Kentucky/Monticello"},
    {name: "America/Kralendijk", label: "America/Kralendijk"},
    {name: "America/La_Paz", label: "America/La_Paz"},
    {name: "America/Lima", label: "America/Lima"},
    {name: "America/Los_Angeles", label: "America/Los_Angeles"},
    {name: "America/Louisville", label: "America/Louisville"},
    {name: "America/Lower_Princes", label: "America/Lower_Princes"},
    {name: "America/Maceio", label: "America/Maceio"},
    {name: "America/Managua", label: "America/Managua"},
    {name: "America/Manaus", label: "America/Manaus"},
    {name: "America/Marigot", label: "America/Marigot"},
    {name: "America/Martinique", label: "America/Martinique"},
    {name: "America/Matamoros", label: "America/Matamoros"},
    {name: "America/Mazatlan", label: "America/Mazatlan"},
    {name: "America/Mendoza", label: "America/Mendoza"},
    {name: "America/Menominee", label: "America/Menominee"},
    {name: "America/Merida", label: "America/Merida"},
    {name: "America/Metlakatla", label: "America/Metlakatla"},
    {name: "America/Mexico_City", label: "America/Mexico_City"},
    {name: "America/Miquelon", label: "America/Miquelon"},
    {name: "America/Moncton", label: "America/Moncton"},
    {name: "America/Monterrey", label: "America/Monterrey"},
    {name: "America/Montevideo", label: "America/Montevideo"},
    {name: "America/Montserrat", label: "America/Montserrat"},
    {name: "America/Nassau", label: "America/Nassau"},
    {name: "America/New_York", label: "America/New_York"},
    {name: "America/Nome", label: "America/Nome"},
    {name: "America/Noronha", label: "America/Noronha"},
    {name: "America/North_Dakota/Beulah", label: "America/North_Dakota/Beulah"},
    {name: "America/North_Dakota/Center", label: "America/North_Dakota/Center"},
    {name: "America/North_Dakota/New_Salem", label: "America/North_Dakota/New_Salem"},
    {name: "America/Ojinaga", label: "America/Ojinaga"},
    {name: "America/Panama", label: "America/Panama"},
    {name: "America/Paramaribo", label: "America/Paramaribo"},
    {name: "America/Phoenix", label: "America/Phoenix"},
    {name: "America/Port-au-Prince", label: "America/Port-au-Prince"},
    {name: "America/Port_of_Spain", label: "America/Port_of_Spain"},
    {name: "America/Porto_Velho", label: "America/Porto_Velho"},
    {name: "America/Puerto_Rico", label: "America/Puerto_Rico"},
    {name: "America/Punta_Arenas", label: "America/Punta_Arenas"},
    {name: "America/Rankin_Inlet", label: "America/Rankin_Inlet"},
    {name: "America/Recife", label: "America/Recife"},
    {name: "America/Regina", label: "America/Regina"},
    {name: "America/Resolute", label: "America/Resolute"},
    {name: "America/Rio_Branco", label: "America/Rio_Branco"},
    {name: "America/Santarem", label: "America/Santarem"},
    {name: "America/Santiago", label: "America/Santiago"},
    {name: "America/Santo_Domingo", label: "America/Santo_Domingo"},
    {name: "America/Sao_Paulo", label: "America/Sao_Paulo"},
    {name: "America/Scoresbysund", label: "America/Scoresbysund"},
    {name: "America/Sitka", label: "America/Sitka"},
    {name: "America/St_Barthelemy", label: "America/St_Barthelemy"},
    {name: "America/St_Johns", label: "America/St_Johns"},
    {name: "America/St_Kitts", label: "America/St_Kitts"},
    {name: "America/St_Lucia", label: "America/St_Lucia"},
    {name: "America/St_Thomas", label: "America/St_Thomas"},
    {name: "America/St_Vincent", label: "America/St_Vincent"},
    {name: "America/Swift_Current", label: "America/Swift_Current"},
    {name: "America/Tegucigalpa", label: "America/Tegucigalpa"},
    {name: "America/Thule", label: "America/Thule"},
    {name: "America/Tijuana", label: "America/Tijuana"},
    {name: "America/Toronto", label: "America/Toronto"},
    {name: "America/Tortola", label: "America/Tortola"},
    {name: "America/Vancouver", label: "America/Vancouver"},
    {name: "America/Whitehorse", label: "America/Whitehorse"},
    {name: "America/Winnipeg", label: "America/Winnipeg"},
    {name: "America/Yakutat", label: "America/Yakutat"},

    {name: "Europe/Amsterdam", label: "Europe/Amsterdam"},
    {name: "Europe/Andorra", label: "Europe/Andorra"},
    {name: "Europe/Astrakhan", label: "Europe/Astrakhan"},
    {name: "Europe/Athens", label: "Europe/Athens"},
    {name: "Europe/Belgrade", label: "Europe/Belgrade"},
    {name: "Europe/Berlin", label: "Europe/Berlin"},
    {name: "Europe/Bratislava", label: "Europe/Bratislava"},
    {name: "Europe/Brussels", label: "Europe/Brussels"},
    {name: "Europe/Bucharest", label: "Europe/Bucharest"},
    {name: "Europe/Budapest", label: "Europe/Budapest"},
    {name: "Europe/Busingen", label: "Europe/Busingen"},
    {name: "Europe/Chisinau", label: "Europe/Chisinau"},
    {name: "Europe/Copenhagen", label: "Europe/Copenhagen"},
    {name: "Europe/Dublin", label: "Europe/Dublin"},
    {name: "Europe/Gibraltar", label: "Europe/Gibraltar"},
    {name: "Europe/Guernsey", label: "Europe/Guernsey"},
    {name: "Europe/Helsinki", label: "Europe/Helsinki"},
    {name: "Europe/Isle_of_Man", label: "Europe/Isle_of_Man"},
    {name: "Europe/Istanbul", label: "Europe/Istanbul"},
    {name: "Europe/Jersey", label: "Europe/Jersey"},
    {name: "Europe/Kaliningrad", label: "Europe/Kaliningrad"},
    {name: "Europe/Kiev", label: "Europe/Kiev"},
    {name: "Europe/Kirov", label: "Europe/Kirov"},
    {name: "Europe/Lisbon", label: "Europe/Lisbon"},
    {name: "Europe/Ljubljana", label: "Europe/Ljubljana"},
    {name: "Europe/London", label: "Europe/London"},
    {name: "Europe/Luxembourg", label: "Europe/Luxembourg"},
    {name: "Europe/Madrid", label: "Europe/Madrid"},
    {name: "Europe/Malta", label: "Europe/Malta"},
    {name: "Europe/Mariehamn", label: "Europe/Mariehamn"},
    {name: "Europe/Minsk", label: "Europe/Minsk"},
    {name: "Europe/Monaco", label: "Europe/Monaco"},
    {name: "Europe/Moscow", label: "Europe/Moscow"},
    {name: "Europe/Oslo", label: "Europe/Oslo"},
    {name: "Europe/Paris", label: "Europe/Paris"},
    {name: "Europe/Podgorica", label: "Europe/Podgorica"},
    {name: "Europe/Prague", label: "Europe/Prague"},
    {name: "Europe/Riga", label: "Europe/Riga"},
    {name: "Europe/Rome", label: "Europe/Rome"},
    {name: "Europe/Samara", label: "Europe/Samara"},
    {name: "Europe/San_Marino", label: "Europe/San_Marino"},
    {name: "Europe/Sarajevo", label: "Europe/Sarajevo"},
    {name: "Europe/Saratov", label: "Europe/Saratov"},
    {name: "Europe/Simferopol", label: "Europe/Simferopol"},
    {name: "Europe/Skopje", label: "Europe/Skopje"},
    {name: "Europe/Sofia", label: "Europe/Sofia"},
    {name: "Europe/Stockholm", label: "Europe/Stockholm"},
    {name: "Europe/Tallinn", label: "Europe/Tallinn"},
    {name: "Europe/Tirane", label: "Europe/Tirane"},
    {name: "Europe/Ulyanovsk", label: "Europe/Ulyanovsk"},
    {name: "Europe/Vaduz", label: "Europe/Vaduz"},
    {name: "Europe/Vatican", label: "Europe/Vatican"},
    {name: "Europe/Vienna", label: "Europe/Vienna"},
    {name: "Europe/Vilnius", label: "Europe/Vilnius"},
    {name: "Europe/Volgograd", label: "Europe/Volgograd"},
    {name: "Europe/Warsaw", label: "Europe/Warsaw"},
    {name: "Europe/Zagreb", label: "Europe/Zagreb"},
    {name: "Europe/Zurich", label: "Europe/Zurich"},

    {name: "Australia/Adelaide", label: "Australia/Adelaide"},
    {name: "Australia/Brisbane", label: "Australia/Brisbane"},
    {name: "Australia/Broken_Hill", label: "Australia/Broken_Hill"},
    {name: "Australia/Darwin", label: "Australia/Darwin"},
    {name: "Australia/Eucla", label: "Australia/Eucla"},
    {name: "Australia/Hobart", label: "Australia/Hobart"},
    {name: "Australia/Lindeman", label: "Australia/Lindeman"},
    {name: "Australia/Lord_Howe", label: "Australia/Lord_Howe"},
    {name: "Australia/Melbourne", label: "Australia/Melbourne"},
    {name: "Australia/Perth", label: "Australia/Perth"},
    {name: "Australia/Sydney", label: "Australia/Sydney"},

    {name: "Indian/Antananarivo", label: "Indian/Antananarivo"},
    {name: "Indian/Chagos", label: "Indian/Chagos"},
    {name: "Indian/Christmas", label: "Indian/Christmas"},
    {name: "Indian/Cocos", label: "Indian/Cocos"},
    {name: "Indian/Comoro", label: "Indian/Comoro"},
    {name: "Indian/Kerguelen", label: "Indian/Kerguelen"},
    {name: "Indian/Mahe", label: "Indian/Mahe"},
    {name: "Indian/Maldives", label: "Indian/Maldives"},
    {name: "Indian/Mauritius", label: "Indian/Mauritius"},
    {name: "Indian/Mayotte", label: "Indian/Mayotte"},
    {name: "Indian/Reunion", label: "Indian/Reunion"},

    {name: "Pacific/Apia", label: "Pacific/Apia"},
    {name: "Pacific/Auckland", label: "Pacific/Auckland"},
    {name: "Pacific/Bougainville", label: "Pacific/Bougainville"},
    {name: "Pacific/Chatham", label: "Pacific/Chatham"},
    {name: "Pacific/Easter", label: "Pacific/Easter"},
    {name: "Pacific/Efate", label: "Pacific/Efate"},
    {name: "Pacific/Enderbury", label: "Pacific/Enderbury"},
    {name: "Pacific/Fakaofo", label: "Pacific/Fakaofo"},
    {name: "Pacific/Fiji", label: "Pacific/Fiji"},
    {name: "Pacific/Funafuti", label: "Pacific/Funafuti"},
    {name: "Pacific/Galapagos", label: "Pacific/Galapagos"},
    {name: "Pacific/Gambier", label: "Pacific/Gambier"},
    {name: "Pacific/Guadalcanal", label: "Pacific/Guadalcanal"},
    {name: "Pacific/Guam", label: "Pacific/Guam"},
    {name: "Pacific/Honolulu", label: "Pacific/Honolulu"},
    {name: "Pacific/Kiritimati", label: "Pacific/Kiritimati"},
    {name: "Pacific/Kosrae", label: "Pacific/Kosrae"},
    {name: "Pacific/Kwajalein", label: "Pacific/Kwajalein"},
    {name: "Pacific/Majuro", label: "Pacific/Majuro"},
    {name: "Pacific/Marquesas", label: "Pacific/Marquesas"},
    {name: "Pacific/Midway", label: "Pacific/Midway"},
    {name: "Pacific/Nauru", label: "Pacific/Nauru"},
    {name: "Pacific/Niue", label: "Pacific/Niue"},
    {name: "Pacific/Norfolk", label: "Pacific/Norfolk"},
    {name: "Pacific/Noumea", label: "Pacific/Noumea"},
    {name: "Pacific/Pago_Pago", label: "Pacific/Pago_Pago"},
    {name: "Pacific/Palau", label: "Pacific/Palau"},
    {name: "Pacific/Pitcairn", label: "Pacific/Pitcairn"},
    {name: "Pacific/Ponape", label: "Pacific/Ponape"},
    {name: "Pacific/Port_Moresby", label: "Pacific/Port_Moresby"},
    {name: "Pacific/Rarotonga", label: "Pacific/Rarotonga"},
    {name: "Pacific/Saipan", label: "Pacific/Saipan"},
    {name: "Pacific/Tahiti", label: "Pacific/Tahiti"},
    {name: "Pacific/Tarawa", label: "Pacific/Tarawa"},
    {name: "Pacific/Tongatapu", label: "Pacific/Tongatapu"},
    {name: "Pacific/Truk", label: "Pacific/Truk"},
    {name: "Pacific/Wake", label: "Pacific/Wake"},
    {name: "Pacific/Wallis", label: "Pacific/Wallis"},

    {name: "Africa/Abidjan", label: "Africa/Abidjan"},
    {name: "Africa/Accra", label: "Africa/Accra"},
    {name: "Africa/Addis_Ababa", label: "Africa/Addis_Ababa"},
    {name: "Africa/Algiers", label: "Africa/Algiers"},
    {name: "Africa/Asmera", label: "Africa/Asmera"},
    {name: "Africa/Bamako", label: "Africa/Bamako"},
    {name: "Africa/Bangui", label: "Africa/Bangui"},
    {name: "Africa/Banjul", label: "Africa/Banjul"},
    {name: "Africa/Bissau", label: "Africa/Bissau"},
    {name: "Africa/Blantyre", label: "Africa/Blantyre"},
    {name: "Africa/Brazzaville", label: "Africa/Brazzaville"},
    {name: "Africa/Bujumbura", label: "Africa/Bujumbura"},
    {name: "Africa/Cairo", label: "Africa/Cairo"},
    {name: "Africa/Casablanca", label: "Africa/Casablanca"},
    {name: "Africa/Ceuta", label: "Africa/Ceuta"},
    {name: "Africa/Conakry", label: "Africa/Conakry"},
    {name: "Africa/Dakar", label: "Africa/Dakar"},
    {name: "Africa/Dar_es_Salaam", label: "Africa/Dar_es_Salaam"},
    {name: "Africa/Djibouti", label: "Africa/Djibouti"},
    {name: "Africa/Douala", label: "Africa/Douala"},
    {name: "Africa/El_Aaiun", label: "Africa/El_Aaiun"},
    {name: "Africa/Freetown", label: "Africa/Freetown"},
    {name: "Africa/Gaborone", label: "Africa/Gaborone"},
    {name: "Africa/Harare", label: "Africa/Harare"},
    {name: "Africa/Johannesburg", label: "Africa/Johannesburg"},
    {name: "Africa/Juba", label: "Africa/Juba"},
    {name: "Africa/Kampala", label: "Africa/Kampala"},
    {name: "Africa/Khartoum", label: "Africa/Khartoum"},
    {name: "Africa/Kigali", label: "Africa/Kigali"},
    {name: "Africa/Kinshasa", label: "Africa/Kinshasa"},
    {name: "Africa/Lagos", label: "Africa/Lagos"},
    {name: "Africa/Libreville", label: "Africa/Libreville"},
    {name: "Africa/Lome", label: "Africa/Lome"},
    {name: "Africa/Luanda", label: "Africa/Luanda"},
    {name: "Africa/Lubumbashi", label: "Africa/Lubumbashi"},
    {name: "Africa/Lusaka", label: "Africa/Lusaka"},
    {name: "Africa/Malabo", label: "Africa/Malabo"},
    {name: "Africa/Maputo", label: "Africa/Maputo"},
    {name: "Africa/Maseru", label: "Africa/Maseru"},
    {name: "Africa/Mbabane", label: "Africa/Mbabane"},
    {name: "Africa/Mogadishu", label: "Africa/Mogadishu"},
    {name: "Africa/Monrovia", label: "Africa/Monrovia"},
    {name: "Africa/Nairobi", label: "Africa/Nairobi"},
    {name: "Africa/Ndjamena", label: "Africa/Ndjamena"},
    {name: "Africa/Niamey", label: "Africa/Niamey"},
    {name: "Africa/Nouakchott", label: "Africa/Nouakchott"},
    {name: "Africa/Ouagadougou", label: "Africa/Ouagadougou"},
    {name: "Africa/Porto-Novo", label: "Africa/Porto-Novo"},
    {name: "Africa/Sao_Tome", label: "Africa/Sao_Tome"},
    {name: "Africa/Tripoli", label: "Africa/Tripoli"},
    {name: "Africa/Tunis", label: "Africa/Tunis"},
    {name: "Africa/Windhoek", label: "Africa/Windhoek"},

    {name: "Asia/Aden", label: "Asia/Aden"},
    {name: "Asia/Almaty", label: "Asia/Almaty"},
    {name: "Asia/Amman", label: "Asia/Amman"},
    {name: "Asia/Anadyr", label: "Asia/Anadyr"},
    {name: "Asia/Aqtau", label: "Asia/Aqtau"},
    {name: "Asia/Aqtobe", label: "Asia/Aqtobe"},
    {name: "Asia/Ashgabat", label: "Asia/Ashgabat"},
    {name: "Asia/Atyrau", label: "Asia/Atyrau"},
    {name: "Asia/Baghdad", label: "Asia/Baghdad"},
    {name: "Asia/Bahrain", label: "Asia/Bahrain"},
    {name: "Asia/Baku", label: "Asia/Baku"},
    {name: "Asia/Bangkok", label: "Asia/Bangkok"},
    {name: "Asia/Barnaul", label: "Asia/Barnaul"},
    {name: "Asia/Beirut", label: "Asia/Beirut"},
    {name: "Asia/Bishkek", label: "Asia/Bishkek"},
    {name: "Asia/Brunei", label: "Asia/Brunei"},
    {name: "Asia/Calcutta", label: "Asia/Calcutta"},
    {name: "Asia/Chita", label: "Asia/Chita"},
    {name: "Asia/Colombo", label: "Asia/Colombo"},
    {name: "Asia/Damascus", label: "Asia/Damascus"},
    {name: "Asia/Dhaka", label: "Asia/Dhaka"},
    {name: "Asia/Dili", label: "Asia/Dili"},
    {name: "Asia/Dubai", label: "Asia/Dubai"},
    {name: "Asia/Dushanbe", label: "Asia/Dushanbe"},
    {name: "Asia/Famagusta", label: "Asia/Famagusta"},
    {name: "Asia/Gaza", label: "Asia/Gaza"},
    {name: "Asia/Hebron", label: "Asia/Hebron"},
    {name: "Asia/Hong_Kong", label: "Asia/Hong_Kong"},
    {name: "Asia/Hovd", label: "Asia/Hovd"},
    {name: "Asia/Irkutsk", label: "Asia/Irkutsk"},
    {name: "Asia/Jakarta", label: "Asia/Jakarta"},
    {name: "Asia/Jayapura", label: "Asia/Jayapura"},
    {name: "Asia/Jerusalem", label: "Asia/Jerusalem"},
    {name: "Asia/Kabul", label: "Asia/Kabul"},
    {name: "Asia/Kamchatka", label: "Asia/Kamchatka"},
    {name: "Asia/Karachi", label: "Asia/Karachi"},
    {name: "Asia/Katmandu", label: "Asia/Katmandu"},
    {name: "Asia/Khandyga", label: "Asia/Khandyga"},
    {name: "Asia/Krasnoyarsk", label: "Asia/Krasnoyarsk"},
    {name: "Asia/Kuala_Lumpur", label: "Asia/Kuala_Lumpur"},
    {name: "Asia/Kuching", label: "Asia/Kuching"},
    {name: "Asia/Kuwait", label: "Asia/Kuwait"},
    {name: "Asia/Macau", label: "Asia/Macau"},
    {name: "Asia/Magadan", label: "Asia/Magadan"},
    {name: "Asia/Makassar", label: "Asia/Makassar"},
    {name: "Asia/Manila", label: "Asia/Manila"},
    {name: "Asia/Muscat", label: "Asia/Muscat"},
    {name: "Asia/Nicosia", label: "Asia/Nicosia"},
    {name: "Asia/Novokuznetsk", label: "Asia/Novokuznetsk"},
    {name: "Asia/Novosibirsk", label: "Asia/Novosibirsk"},
    {name: "Asia/Omsk", label: "Asia/Omsk"},
    {name: "Asia/Oral", label: "Asia/Oral"},
    {name: "Asia/Phnom_Penh", label: "Asia/Phnom_Penh"},
    {name: "Asia/Pontianak", label: "Asia/Pontianak"},
    {name: "Asia/Pyongyang", label: "Asia/Pyongyang"},
    {name: "Asia/Qatar", label: "Asia/Qatar"},
    {name: "Asia/Qostanay", label: "Asia/Qostanay"},
    {name: "Asia/Qyzylorda", label: "Asia/Qyzylorda"},
    {name: "Asia/Rangoon", label: "Asia/Rangoon"},
    {name: "Asia/Riyadh", label: "Asia/Riyadh"},
    {name: "Asia/Saigon", label: "Asia/Saigon"},
    {name: "Asia/Sakhalin", label: "Asia/Sakhalin"},
    {name: "Asia/Samarkand", label: "Asia/Samarkand"},
    {name: "Asia/Seoul", label: "Asia/Seoul"},
    {name: "Asia/Shanghai", label: "Asia/Shanghai"},
    {name: "Asia/Singapore", label: "Asia/Singapore"},
    {name: "Asia/Srednekolymsk", label: "Asia/Srednekolymsk"},
    {name: "Asia/Taipei", label: "Asia/Taipei"},
    {name: "Asia/Tashkent", label: "Asia/Tashkent"},
    {name: "Asia/Tbilisi", label: "Asia/Tbilisi"},
    {name: "Asia/Tehran", label: "Asia/Tehran"},
    {name: "Asia/Thimphu", label: "Asia/Thimphu"},
    {name: "Asia/Tokyo", label: "Asia/Tokyo"},
    {name: "Asia/Tomsk", label: "Asia/Tomsk"},
    {name: "Asia/Ulaanbaatar", label: "Asia/Ulaanbaatar"},
    {name: "Asia/Urumqi", label: "Asia/Urumqi"},
    {name: "Asia/Ust-Nera", label: "Asia/Ust-Nera"},
    {name: "Asia/Vientiane", label: "Asia/Vientiane"},
    {name: "Asia/Vladivostok", label: "Asia/Vladivostok"},
    {name: "Asia/Yakutsk", label: "Asia/Yakutsk"},
    {name: "Asia/Yekaterinburg", label: "Asia/Yekaterinburg"},
    {name: "Asia/Yerevan", label: "Asia/Yerevan"},
    
    {name: "Atlantic/Azores", label: "Atlantic/Azores"},
    {name: "Atlantic/Bermuda", label: "Atlantic/Bermuda"},
    {name: "Atlantic/Canary", label: "Atlantic/Canary"},
    {name: "Atlantic/Cape_Verde", label: "Atlantic/Cape_Verde"},
    {name: "Atlantic/Faeroe", label: "Atlantic/Faeroe"},
    {name: "Atlantic/Madeira", label: "Atlantic/Madeira"},
    {name: "Atlantic/Reykjavik", label: "Atlantic/Reykjavik"},
    {name: "Atlantic/South_Georgia", label: "Atlantic/South_Georgia"},
    {name: "Atlantic/St_Helena", label: "Atlantic/St_Helena"},
    {name: "Atlantic/Stanley", label: "Atlantic/Stanley"},
    {name: "Antarctica/Casey", label: "Antarctica/Casey"},
    {name: "Antarctica/Davis", label: "Antarctica/Davis"},
    {name: "Antarctica/DumontDUrville", label: "Antarctica/DumontDUrville"},
    {name: "Antarctica/Macquarie", label: "Antarctica/Macquarie"},
    {name: "Antarctica/Mawson", label: "Antarctica/Mawson"},
    {name: "Antarctica/McMurdo", label: "Antarctica/McMurdo"},
    {name: "Antarctica/Palmer", label: "Antarctica/Palmer"},
    {name: "Antarctica/Rothera", label: "Antarctica/Rothera"},
    {name: "Antarctica/Syowa", label: "Antarctica/Syowa"},
    {name: "Antarctica/Troll", label: "Antarctica/Troll"},
    {name: "Antarctica/Vostok", label: "Antarctica/Vostok"},
    {name: "Arctic/Longyearbyen", label: "Arctic/Longyearbyen"},
    ]