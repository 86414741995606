import { FC, useEffect } from 'react'
import styles from './RobotList.module.scss'
import { RobotF } from '@src/logic/contexts/Robots/RobotsFrontend.type'
import { Controller, useForm } from 'react-hook-form'
import { useDataProvider, useNotify } from 'react-admin'
import { useSubmitLoader } from '@src/logic/contexts/utils/SubmitContext'
import { useRefresh } from 'react-admin'
import { LxLoadingSpinner } from '@src/components/loader/loadingSpinner'
import modalStyles from '@components/modal/modal.module.scss'
import { LxSelect } from '@src/components/select/select'
import { useFormNotify } from '@src/components/formNotify/useFormNotify'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { cn } from '@src/utils/cn'

// Updated SubscriptionFormType with specified field names
export type AssignFormType = {
  subscriptionType: string
}

// Default values for the form
export const defaultAssignFormValues = (): AssignFormType => ({
  subscriptionType: '',
})

// Validation schema for the form
export const AssignFormValidation = yupResolver(
  yup.object().shape({
    subscriptionType: yup.string().required('Subscription Type is required'),
  })
)

interface AssignRobotProps {
  onClose: () => void
  robot: RobotF | null
}

export const AssignLicenseRobot: FC<AssignRobotProps> = ({ robot, onClose }) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const { isFormSubmitting, setIsFormSubmitting } = useSubmitLoader()
  const refresh = useRefresh()

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, submitCount, isValid },
  } = useForm({
    defaultValues: defaultAssignFormValues(), // Use default values from the function
    resolver: AssignFormValidation,
  })

  useFormNotify(errors, submitCount, isValid)

  useEffect(() => {
    // Reset the form when robot changes
    if (robot) {
      reset({ subscriptionType: '' }) // Reset to empty or to robot related data if needed
    } else {
      reset(defaultAssignFormValues())
    }
  }, [robot, reset])

  const onSubmit = async (formData) => {
    if (isFormSubmitting) {
      return
    }

    setIsFormSubmitting(true)

    try {
      const { subscriptionType } = formData

      if (!subscriptionType) {
        notify('Subscription type is not selected', { type: 'error' })
        return
      }

      const assignData = {
        id: robot?.id,
        customer_acc_id: robot?.accountId,
        subscription_type_id: subscriptionType,
        method: 'assignLicenseToRobot',
      }
      // for adminApp, we will put method as update and customize the call in DataProvider.tsx
      await dataProvider.create('Subscription', { data: assignData })

      notify('License assignt to robot successfully', { type: 'success' })
      refresh()
    } catch (error) {
      const errorMessage = error.message || 'Something went wrong. Please try again later.'
      notify(errorMessage, { type: 'error' })
    } finally {
      handleDialogClose()
    }
  }

  const handleDialogClose = () => {
    reset({ subscriptionType: '' })
    onClose()
    setIsFormSubmitting(false)
  }

  return (
    <div className={modalStyles.modalMd}>
      <div className={modalStyles.modalContentContainer}>
        {isFormSubmitting && (
          <div className={styles.LoadingOverlay}>
            <LxLoadingSpinner />
          </div>
        )}

        <div className={modalStyles.dialogHeader}>
          <h3>Assign License to - {robot?.serial}</h3>
        </div>

        <div className={modalStyles.dialogContent}>
          {robot ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                name="subscriptionType" // Remove the extra space here
                control={control}
                render={({ field, fieldState }) => (
                  <LxSelect
                    value={field.value}
                    hasError={!!fieldState.error}
                    shrinked
                    placeholder={'Select Subscription Type'}
                    onChange={field.onChange}
                    options={[
                      { value: 'QTASE1', label: 'QTrobot Autism Enterprise (QTASE1)' },
                      { value: 'QTAH1', label: 'QTrobot Autism Home (QTAH1)' },
                      { value: 'QTASP1', label: 'QTrobot Autism Professional (QTASP1)' },
                    ]}
                  />
                )}
              />
              {errors.subscriptionType && <p>{errors.subscriptionType.message}</p>}
            </form>
          ) : (
            <p>No robot provided</p>
          )}
        </div>

        <div className={modalStyles.dialogActions}>
          <div
            className={cn('lxActionButton lxActionButtonDefaultSize')}
            onClick={handleDialogClose}
          >
            <span>Cancel</span>
          </div>
          <div
            className={cn('lxActionButton lxActionButtonFilled lxActionButtonDefaultSize')}
            onClick={handleSubmit(onSubmit)}
          >
            <span>Assign the License</span>
          </div>
        </div>
      </div>
    </div>
  )
}
