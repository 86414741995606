import React, { useContext, useMemo} from 'react';
import { useGetList } from 'react-admin';
import { RobotB } from '@src/logic/contexts/Robots/RobotsBackend.type'
import { RobotF } from '@src/logic/contexts/Robots/RobotsFrontend.type';
import { createContextFactory } from '@logic/contexts/createContextFactory.ts';
import { isNilOrEmpty } from '@src/utils/isNilOrEmpty.ts'
import { DispatchType, FilterValues, useDebounceFilter } from '@src/hooks/useDebounceFilter.ts'

interface RobotContextType {
  robotList: RobotF[] | null;
  isLoading: boolean;
  filterValues: FilterValues;
  dispatch: DispatchType;
}

const RobotListContextDefault = {
  robotList: null,
  isLoading: true,
  filterValues: {
    Q: '',
    selection: 'all',
  },
  dispatch: () => {},
};

const RobotListContext = createContextFactory<RobotContextType>(RobotListContextDefault);

const mapServerRobotListToClient = (data: RobotB[] | undefined): RobotF[] | null => {
  if (isNilOrEmpty(data)) {
    return null;
  }

  return data!.map((robot: RobotB) => ({
    id: robot.id,
    serial: robot.serial,
    accountId: robot.account_id,
    accountName: robot.account_name,
    ownerAccountName: robot.owner_account_name,
    versionInfo: {
      model: robot.version_info.model,
      softwareVersion: robot.version_info.software_version,
      versionNumber: robot.version_info.version_number,
      lastUpdate: robot.version_info.last_update
    },
    usageInfo: {
      lastActivityDate: robot.usage_info.last_activity_date
    },
    creationInfo: {
      date: robot.creation_info.date ?? '',
      by: robot.creation_info.by ?? '',
      userName: robot.creation_info.user_name ?? ''
    },
    assignedLicenses: robot.assigned_licenses,
    configuration: {
      voice: {
        default: robot.configuration.voice.default,
        installed: robot.configuration.voice.installed
      }
    }
  }));
};

export const RobotListProvider: React.FC = ({ children, filter }) => {
  const initialFilterValues = { Q: ''};
  const [filterValues, dispatch] = useDebounceFilter(initialFilterValues);

  const { data, isLoading, error } = useGetList<RobotB>(
    'Robot',
    {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'last_update', order: 'DESC' },
      filter: filter ? filter : filterValues
    }
  );

  const sanitizedRobotList = useMemo(() => mapServerRobotListToClient(data), [data]);

  // Handle errors
  if (error) {
    // You can handle the error here, e.g., notify the user, log the error, etc.
    // You can also return an error message or a different UI in case of an error.
    return (
      <div>
        <p>An error occurred while fetching learner data. Please try again later.</p>
      </div>
    );
  }

  return (
    <RobotListContext.Provider value={{ robotList: sanitizedRobotList, filterValues, isLoading, dispatch }}>
      {children}
    </RobotListContext.Provider>
  );
};

export const useRobotsList = (): RobotContextType => {
  return useContext(RobotListContext);
};
