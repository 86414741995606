export const countriesList = [
  { name: 'United Kingdom', label: 'United Kingdom', code: '+44' },
  { name: 'United States', label: 'United States', code: '+1' },
  { name: 'Canada', label: 'Canada', code: '+1' },
  { name: 'France', label: 'France', code: '+33' },
  { name: 'Afghanistan', label: 'Afghanistan', code: '+93' },
  { name: 'Albania', label: 'Albania', code: '+355' },
  { name: 'Algeria', label: 'Algeria', code: '+213' },
  { name: 'Andorra', label: 'Andorra', code: '+376' },
  { name: 'Angola', label: 'Angola', code: '+244' },
  { name: 'Antigua and Barbuda', label: 'Antigua and Barbuda', code: '+1-268' },
  { name: 'Argentina', label: 'Argentina', code: '+54' },
  { name: 'Armenia', label: 'Armenia', code: '+374' },
  { name: 'Australia', label: 'Australia', code: '+61' },
  { name: 'Austria', label: 'Austria', code: '+43' },
  { name: 'Azerbaijan', label: 'Azerbaijan', code: '+994' },
  { name: 'Bahamas', label: 'Bahamas', code: '+1-242' },
  { name: 'Bahrain', label: 'Bahrain', code: '+973' },
  { name: 'Bangladesh', label: 'Bangladesh', code: '+880' },
  { name: 'Barbados', label: 'Barbados', code: '+1-246' },
  { name: 'Belarus', label: 'Belarus', code: '+375' },
  { name: 'Belgium', label: 'Belgium', code: '+32' },
  { name: 'Belize', label: 'Belize', code: '+501' },
  { name: 'Benin', label: 'Benin', code: '+229' },
  { name: 'Bhutan', label: 'Bhutan', code: '+975' },
  { name: 'Bolivia', label: 'Bolivia', code: '+591' },
  { name: 'Bosnia and Herzegovina', label: 'Bosnia and Herzegovina', code: '+387' },
  { name: 'Botswana', label: 'Botswana', code: '+267' },
  { name: 'Brazil', label: 'Brazil', code: '+55' },
  { name: 'Brunei', label: 'Brunei', code: '+673' },
  { name: 'Bulgaria', label: 'Bulgaria', code: '+359' },
  { name: 'Burkina Faso', label: 'Burkina Faso', code: '+226' },
  { name: 'Burundi', label: 'Burundi', code: '+257' },
  { name: 'Cabo Verde', label: 'Cabo Verde', code: '+238' },
  { name: 'Cambodia', label: 'Cambodia', code: '+855' },
  { name: 'Cameroon', label: 'Cameroon', code: '+237' },
  { name: 'Central African Republic', label: 'Central African Republic', code: '+236' },
  { name: 'Chad', label: 'Chad', code: '+235' },
  { name: 'Chile', label: 'Chile', code: '+56' },
  { name: 'China', label: 'China', code: '+86' },
  { name: 'Colombia', label: 'Colombia', code: '+57' },
  { name: 'Comoros', label: 'Comoros', code: '+269' },
  { name: 'Congo (Congo-Brazzaville)', label: 'Congo (Congo-Brazzaville)', code: '+242' },
  { name: 'Congo (Congo-Kinshasa)', label: 'Congo (Congo-Kinshasa)', code: '+243' },
  { name: 'Costa Rica', label: 'Costa Rica', code: '+506' },
  { name: 'Croatia', label: 'Croatia', code: '+385' },
  { name: 'Cuba', label: 'Cuba', code: '+53' },
  { name: 'Cyprus', label: 'Cyprus', code: '+357' },
  { name: 'Czechia (Czech Republic)', label: 'Czechia (Czech Republic)', code: '+420' },
  { name: 'Denmark', label: 'Denmark', code: '+45' },
  { name: 'Djibouti', label: 'Djibouti', code: '+253' },
  { name: 'Dominica', label: 'Dominica', code: '+1-767' },
  { name: 'Dominican Republic', label: 'Dominican Republic', code: '+1-809' },
  { name: 'Ecuador', label: 'Ecuador', code: '+593' },
  { name: 'Egypt', label: 'Egypt', code: '+20' },
  { name: 'El Salvador', label: 'El Salvador', code: '+503' },
  { name: 'Equatorial Guinea', label: 'Equatorial Guinea', code: '+240' },
  { name: 'Eritrea', label: 'Eritrea', code: '+291' },
  { name: 'Estonia', label: 'Estonia', code: '+372' },
  { name: 'Eswatini', label: 'Eswatini', code: '+268' },
  { name: 'Ethiopia', label: 'Ethiopia', code: '+251' },
  { name: 'Fiji', label: 'Fiji', code: '+679' },
  { name: 'Finland', label: 'Finland', code: '+358' },
  { name: 'Gabon', label: 'Gabon', code: '+241' },
  { name: 'Gambia', label: 'Gambia', code: '+220' },
  { name: 'Georgia', label: 'Georgia', code: '+995' },
  { name: 'Germany', label: 'Germany', code: '+49' },
  { name: 'Ghana', label: 'Ghana', code: '+233' },
  { name: 'Greece', label: 'Greece', code: '+30' },
  { name: 'Grenada', label: 'Grenada', code: '+1-473' },
  { name: 'Guatemala', label: 'Guatemala', code: '+502' },
  { name: 'Guinea', label: 'Guinea', code: '+224' },
  { name: 'Guinea-Bissau', label: 'Guinea-Bissau', code: '+245' },
  { name: 'Guyana', label: 'Guyana', code: '+592' },
  { name: 'Haiti', label: 'Haiti', code: '+509' },
  { name: 'Honduras', label: 'Honduras', code: '+504' },
  { name: 'Hungary', label: 'Hungary', code: '+36' },
  { name: 'Iceland', label: 'Iceland', code: '+354' },
  { name: 'India', label: 'India', code: '+91' },
  { name: 'Indonesia', label: 'Indonesia', code: '+62' },
  { name: 'Iran', label: 'Iran', code: '+98' },
  { name: 'Iraq', label: 'Iraq', code: '+964' },
  { name: 'Ireland', label: 'Ireland', code: '+353' },
  { name: 'Israel', label: 'Israel', code: '+972' },
  { name: 'Italy', label: 'Italy', code: '+39' },
  { name: 'Jamaica', label: 'Jamaica', code: '+1-876' },
  { name: 'Japan', label: 'Japan', code: '+81' },
  { name: 'Jordan', label: 'Jordan', code: '+962' },
  { name: 'Kazakhstan', label: 'Kazakhstan', code: '+7' },
  { name: 'Kenya', label: 'Kenya', code: '+254' },
  { name: 'Kiribati', label: 'Kiribati', code: '+686' },
  { name: 'Korea (North)', label: 'Korea (North)', code: '+850' },
  { name: 'Korea (South)', label: 'Korea (South)', code: '+82' },
  { name: 'Kuwait', label: 'Kuwait', code: '+965' },
  { name: 'Kyrgyzstan', label: 'Kyrgyzstan', code: '+996' },
  { name: 'Laos', label: 'Laos', code: '+856' },
  { name: 'Latvia', label: 'Latvia', code: '+371' },
  { name: 'Lebanon', label: 'Lebanon', code: '+961' },
  { name: 'Lesotho', label: 'Lesotho', code: '+266' },
  { name: 'Liberia', label: 'Liberia', code: '+231' },
  { name: 'Libya', label: 'Libya', code: '+218' },
  { name: 'Liechtenstein', label: 'Liechtenstein', code: '+423' },
  { name: 'Lithuania', label: 'Lithuania', code: '+370' },
  { name: 'Luxembourg', label: 'Luxembourg', code: '+352' },
  { name: 'Madagascar', label: 'Madagascar', code: '+261' },
  { name: 'Malawi', label: 'Malawi', code: '+265' },
  { name: 'Malaysia', label: 'Malaysia', code: '+60' },
  { name: 'Maldives', label: 'Maldives', code: '+960' },
  { name: 'Mali', label: 'Mali', code: '+223' },
  { name: 'Malta', label: 'Malta', code: '+356' },
  { name: 'Marshall Islands', label: 'Marshall Islands', code: '+692' },
  { name: 'Mauritania', label: 'Mauritania', code: '+222' },
  { name: 'Mauritius', label: 'Mauritius', code: '+230' },
  { name: 'Mexico', label: 'Mexico', code: '+52' },
  { name: 'Micronesia', label: 'Micronesia', code: '+691' },
  { name: 'Moldova', label: 'Moldova', code: '+373' },
  { name: 'Monaco', label: 'Monaco', code: '+377' },
  { name: 'Mongolia', label: 'Mongolia', code: '+976' },
  { name: 'Montenegro', label: 'Montenegro', code: '+382' },
  { name: 'Morocco', label: 'Morocco', code: '+212' },
  { name: 'Mozambique', label: 'Mozambique', code: '+258' },
  { name: 'Myanmar', label: 'Myanmar', code: '+95' },
  { name: 'Namibia', label: 'Namibia', code: '+264' },
  { name: 'Nauru', label: 'Nauru', code: '+674' },
  { name: 'Nepal', label: 'Nepal', code: '+977' },
  { name: 'Netherlands', label: 'Netherlands', code: '+31' },
  { name: 'New Zealand', label: 'New Zealand', code: '+64' },
  { name: 'Nicaragua', label: 'Nicaragua', code: '+505' },
  { name: 'Niger', label: 'Niger', code: '+227' },
  { name: 'Nigeria', label: 'Nigeria', code: '+234' },
  { name: 'North Macedonia', label: 'North Macedonia', code: '+389' },
  { name: 'Norway', label: 'Norway', code: '+47' },
  { name: 'Oman', label: 'Oman', code: '+968' },
  { name: 'Pakistan', label: 'Pakistan', code: '+92' },
  { name: 'Palau', label: 'Palau', code: '+680' },
  { name: 'Panama', label: 'Panama', code: '+507' },
  { name: 'Papua New Guinea', label: 'Papua New Guinea', code: '+675' },
  { name: 'Paraguay', label: 'Paraguay', code: '+595' },
  { name: 'Peru', label: 'Peru', code: '+51' },
  { name: 'Philippines', label: 'Philippines', code: '+63' },
  { name: 'Poland', label: 'Poland', code: '+48' },
  { name: 'Portugal', label: 'Portugal', code: '+351' },
  { name: 'Qatar', label: 'Qatar', code: '+974' },
  { name: 'Romania', label: 'Romania', code: '+40' },
  { name: 'Russia', label: 'Russia', code: '+7' },
  { name: 'Rwanda', label: 'Rwanda', code: '+250' },
  { name: 'Saint Kitts and Nevis', label: 'Saint Kitts and Nevis', code: '+1-869' },
  { name: 'Saint Lucia', label: 'Saint Lucia', code: '+1-758' },
  { name: 'Saint Vincent and the Grenadines', label: 'Saint Vincent and the Grenadines', code: '+1-784' },
  { name: 'Samoa', label: 'Samoa', code: '+685' },
  { name: 'San Marino', label: 'San Marino', code: '+378' },
  { name: 'Sao Tome and Principe', label: 'Sao Tome and Principe', code: '+239' },
  { name: 'Saudi Arabia', label: 'Saudi Arabia', code: '+966' },
  { name: 'Senegal', label: 'Senegal', code: '+221' },
  { name: 'Serbia', label: 'Serbia', code: '+381' },
  { name: 'Seychelles', label: 'Seychelles', code: '+248' },
  { name: 'Sierra Leone', label: 'Sierra Leone', code: '+232' },
  { name: 'Singapore', label: 'Singapore', code: '+65' },
  { name: 'Slovakia', label: 'Slovakia', code: '+421' },
  { name: 'Slovenia', label: 'Slovenia', code: '+386' },
  { name: 'Solomon Islands', label: 'Solomon Islands', code: '+677' },
  { name: 'Somalia', label: 'Somalia', code: '+252' },
  { name: 'South Africa', label: 'South Africa', code: '+27' },
  { name: 'South Sudan', label: 'South Sudan', code: '+211' },
  { name: 'Spain', label: 'Spain', code: '+34' },
  { name: 'Sri Lanka', label: 'Sri Lanka', code: '+94' },
  { name: 'Sudan', label: 'Sudan', code: '+249' },
  { name: 'Suriname', label: 'Suriname', code: '+597' },
  { name: 'Sweden', label: 'Sweden', code: '+46' },
  { name: 'Switzerland', label: 'Switzerland', code: '+41' },
  { name: 'Syria', label: 'Syria', code: '+963' },
  { name: 'Taiwan', label: 'Taiwan', code: '+886' },
  { name: 'Tajikistan', label: 'Tajikistan', code: '+992' },
  { name: 'Tanzania', label: 'Tanzania', code: '+255' },
  { name: 'Thailand', label: 'Thailand', code: '+66' },
  { name: 'Timor-Leste', label: 'Timor-Leste', code: '+670' },
  { name: 'Togo', label: 'Togo', code: '+228' },
  { name: 'Tonga', label: 'Tonga', code: '+676' },
  { name: 'Trinidad and Tobago', label: 'Trinidad and Tobago', code: '+1-868' },
  { name: 'Tunisia', label: 'Tunisia', code: '+216' },
  { name: 'Turkey', label: 'Turkey', code: '+90' },
  { name: 'Turkmenistan', label: 'Turkmenistan', code: '+993' },
  { name: 'Tuvalu', label: 'Tuvalu', code: '+688' },
  { name: 'Uganda', label: 'Uganda', code: '+256' },
  { name: 'Ukraine', label: 'Ukraine', code: '+380' },
  { name: 'United Arab Emirates', label: 'United Arab Emirates', code: '+971' },
  { name: 'Uruguay', label: 'Uruguay', code: '+598' },
  { name: 'Uzbekistan', label: 'Uzbekistan', code: '+998' },
  { name: 'Vanuatu', label: 'Vanuatu', code: '+678' },
  { name: 'Vatican City', label: 'Vatican City', code: '+379' },
  { name: 'Venezuela', label: 'Venezuela', code: '+58' },
  { name: 'Vietnam', label: 'Vietnam', code: '+84' },
  { name: 'Yemen', label: 'Yemen', code: '+967' },
  { name: 'Zambia', label: 'Zambia', code: '+260' },
  { name: 'Zimbabwe', label: 'Zimbabwe', code: '+263' },
]
