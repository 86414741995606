import AccountList from './AccountList.tsx'
import { AccountListProvider } from '@logic/contexts/Accounts/AccountsListsContext.tsx'
import AccountCreate from './CreateOrEdit/AccountCreate.tsx'
import CombinedAccCreation from './CreateOrEdit/combined/CombinedAccCreation.tsx'

export default {
  list: <AccountListProvider><AccountList/></AccountListProvider>,
  create: AccountCreate,
  combined: CombinedAccCreation
}
