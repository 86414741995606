import { useState, useCallback } from 'react'
import { useContext, useEffect } from 'react'
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom'

function useBlocker(blocker: (tx: any) => void, when = true) {
    const { navigator } = useContext(NavigationContext)
  
    useEffect(() => {
      if (!when) return
  
      const push = navigator.push
      const replace = navigator.replace
  
      navigator.push = (...args) => {
        blocker({
          action: 'PUSH',
          location: args[0],
          retry: () => push(...args),
        })
      }
  
      navigator.replace = (...args) => {
        blocker({
          action: 'REPLACE',
          location: args[0],
          retry: () => replace(...args),
        })
      }
  
      return () => {
        navigator.push = push
        navigator.replace = replace
      }
    }, [blocker, navigator, when])
  }


  export function useConfirmNavigation(when: boolean, message: string) {
    const [confirmNavigation, setConfirmNavigation] = useState(false)
    const [lastNavigation, setLastNavigation] = useState<any>(null)
  
    const blocker = useCallback(
      (tx) => {
        if (confirmNavigation) {
          setConfirmNavigation(false)
          tx.retry()
        } else {
          setLastNavigation(tx)
          setConfirmNavigation(true)
        }
      },
      [confirmNavigation]
    )
  
    useBlocker(blocker, when)
  
    const confirm = () => {
      if (lastNavigation) {
        lastNavigation.retry()
        setLastNavigation(null)
      }
      setConfirmNavigation(false) // Close the dialog after confirmation
    }
  
    const cancel = () => {
      setLastNavigation(null)
      setConfirmNavigation(false) // Close the dialog when canceled
    }
  
    return { confirmNavigation, confirm, cancel, message }
  }
