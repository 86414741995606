import { Controller, useForm } from 'react-hook-form'
import React, { FC } from 'react'
import styles from './CombinedForm.module.scss'
import { cn } from '@src/utils/cn.ts'
import { LxSelect } from '@components/select/select.tsx'
import { yupResolver } from '@hookform/resolvers/yup'
import { useFormNotify } from '@components/formNotify/useFormNotify.tsx'
import * as yup from 'yup'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

// Updated SubscriptionFormType with specified field names
export type SubscriptionFormType = {
  typeId: string
  startDate: string
  endDate: string
  accountId: string
  noOfPurchasedLicenses: number
}

// Default values for the form
export const defaultSubscriptionFormValues = (accountId: string): SubscriptionFormType => {
  const today = dayjs().format('YYYY-MM-DD')
  const oneYearLater = dayjs().add(1, 'year').format('YYYY-MM-DD')

  return {
    typeId: '',
    startDate: today,
    endDate: oneYearLater,
    accountId: accountId,
    noOfPurchasedLicenses: 1,
  }
}

// Validation schema for the form
export const SubscriptionFormValidation = yupResolver(
  yup
    .object()
    .shape({
      typeId: yup.string().required('Subscription Type is required'),
      startDate: yup.date().required('Start date is required'),
      endDate: yup.date().required('End date is required').min(yup.ref('startDate'), 'End date must be after start date'),
      accountId: yup.string().required('Account is required'),
      noOfPurchasedLicenses: yup.number().required('No of License is required'),
    })
    .required()
)

// Function to parse form data to backend format, This will be called by SubscriptionCreate
export const parseSubscriptionFormToBackend = (formData: SubscriptionFormType) => ({
  type_id: formData.typeId,
  customer_acc_id: formData.accountId,
  start: formData.startDate,
  end: formData.endDate,
  no_of_purchased_licenses: formData.noOfPurchasedLicenses,
})

// Component props
type Props = {
  onSubmit: (formData: SubscriptionFormType) => void
  onCancel: () => void
  accountId: string
}

export const SubscriptionForm: FC<Props> = ({ onSubmit, accountId }) => {
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors, submitCount, isValid },
  } = useForm({
    defaultValues: defaultSubscriptionFormValues(accountId),
    resolver: SubscriptionFormValidation,
  })

  const startDateWatch = watch('startDate')

  useFormNotify(errors, submitCount, isValid)

  const formatSelectedDate = (date: dayjs.Dayjs | null) => {
    if (!date) return null
    // Create a new UTC date at midnight for the selected date
    return dayjs(date.format('YYYY-MM-DD')).format('YYYY-MM-DD')
  }

  return (
    <form className={styles.subscriptionForm} onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name='typeId'
        control={control}
        render={({ field, fieldState }) => (
          <div className={cn(styles.inputFieldContainer, { ['formFieldRequired']: !!fieldState.error })}>
            <label className={cn(styles.floatingLabel, styles.floatingLabelActive)}>Subscription Type</label>
            <LxSelect
              value={field.value}
              hasError={!!fieldState.error}
              shrinked
              placeholder={'Select Subscription Type'}
              onChange={field.onChange}
              options={[
                { value: 'QTASE1', label: 'QTrobot Autism Enterprise (QTASE1)' },
                { value: 'QTAH1', label: 'QTrobot Autism Home (QTAH1)' },
                { value: 'QTASP1', label: 'QTrobot Autism Professional (QTASP1)' },
              ]}
            />
          </div>
        )}
      />

      <div className={styles.subDate}>
        <Controller
          name='startDate'
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <div className={cn(styles.inputFieldContainer, { ['formFieldRequired']: !!error })}>
              <label
                className={cn(styles.floatingLabel, {
                  [styles.floatingLabelActive]: value, // Activate label style when value is not empty
                })}
              >
                Start Date
              </label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  format='MMM DD, YYYY'
                  className={cn(styles.datePicker, { [styles.formFieldRequired]: !!error })}
                  onChange={(newValue) => onChange(formatSelectedDate(newValue))}
                  value={value ? dayjs(value) : null}
                  timezone='UTC'
                />
              </LocalizationProvider>
            </div>
          )}
        />

        <Controller
          name='endDate'
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <div className={cn(styles.inputFieldContainer, { ['formFieldRequired']: !!error })}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <label
                  className={cn(styles.floatingLabel, {
                    [styles.floatingLabelActive]: value, // Activate label style when value is not empty
                  })}
                >
                  End Date
                </label>
                <DatePicker
                  format='MMM DD, YYYY'
                  minDate={dayjs(startDateWatch).add(1, 'day')}
                  className={cn(styles.datePicker, { [styles.formFieldRequired]: !!error })}
                  onChange={(newValue) => onChange(formatSelectedDate(newValue))}
                  value={value ? dayjs(value) : null}
                  timezone='UTC'
                />
              </LocalizationProvider>
            </div>
          )}
        />
      </div>
      <Controller
        name='noOfPurchasedLicenses'
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <div className={cn(styles.inputFieldContainer, { ['formFieldRequired']: !!error })}>
            <label
              className={cn(styles.floatingLabel, {
                [styles.floatingLabelActive]: value, // Activate label style when value is not empty
              })}
            >
              Number of Licenses
            </label>
            <input
              className={cn('pristineInput', styles.inputField)}
              type='number'
              value={value}
              min={1}
              onChange={(e) => onChange(e.target.value)}
              placeholder=' '
            />
          </div>
        )}
      />

      <div className={styles.formActions}>
        <div></div>
        <div className={cn('lxActionButton lxActionButtonFilled lxActionButtonDefaultSize')} onClick={handleSubmit(onSubmit)}>
          <span>Create Subscription</span>
        </div>
      </div>
    </form>
  )
}
