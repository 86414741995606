import {
  AccountForm,
  AccountFormType,
  parseAccountFormToBackend,
} from './AccountForm.tsx'

import { useNavigate } from 'react-router-dom'
import { useDataProvider, useNotify } from 'react-admin'
import { LxIcon } from '@components/icon/Icon.tsx'
import styles from './AccountForm.module.scss'
import { ActivitiesIcon } from '@icons/index.ts'
import { useSubmitLoader } from '@src/logic/contexts/utils/SubmitContext.tsx'

export const AccountCreate = () => {
  const navigate = useNavigate()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const {isFormSubmitting, setIsFormSubmitting} = useSubmitLoader();

  const onSubmit = async (formData: AccountFormType) => {
    if (isFormSubmitting) {return};

    setIsFormSubmitting(true);
    try {
      await dataProvider.create('Account', {
        data: parseAccountFormToBackend(formData),
      })

      notify('Account created successfully', { type: 'success' })
      backToAccounts()
    } catch (error) {
      const errorMessage =
        error.message || 'Something went wrong, Please try again after sometime'
      notify(errorMessage, { type: 'error' })
    } finally {
      setIsFormSubmitting(false);
    }
  }

  const backToAccounts = () => {
    navigate('/Accounts') // this will navigate to the previous page
  }


  return (
    <div>
      <div>
        <div class={styles.ProfileHeader}>
          <LxIcon
            icon={ActivitiesIcon}
            sxStyles={{ height: '80px', width: '80px' }}
          ></LxIcon>
          <h3>Add New Account</h3>
        </div>
      </div>
      <AccountForm onSubmit={onSubmit} onCancel={backToAccounts}  isSubmitting={isFormSubmitting}></AccountForm>
    </div>
  )
}

export default AccountCreate
