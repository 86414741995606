import { useMsal } from '@azure/msal-react'
import { createContext, useContext, useEffect, useRef, useState } from 'react'

interface UserData {
  currentUser: User | null
  isUserLoading: boolean
}

interface User {
  id: string
  name: string
  userRole: string[]
  accountName: string
  email: string
}

const UserContext = createContext<UserData | null>(null)

export const useUserContext = () => {
  return useContext(UserContext)
}

export const UserProvider = ({ children, dataProvider, userId }: any) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null)
  const [isUserLoading, setIsUserLoading] = useState(true)
  const isFetching = useRef(false)
  const { instance } = useMsal()

  useEffect(() => {
    const fetchData = async () => {
      if (isFetching.current) return // Return early if a fetch is ongoing

      try {
        isFetching.current = true
        if (dataProvider) {
          if (userId) {
            const response = await dataProvider.getOne('User', {
              id: userId,
            })
            // Check if there is at least one item in the response
            if (response.data) {
              const data = response.data
              const userDataSubset = {
                id: data.id,
                name: data.first_name + ' ' + data.last_name,
                userRole: data.user_role,
                roles: data.roles,
                accountName: data.account_name,
                email: data.primary_email,
                // associated_users: data.associated_users,
                // associated_learners: data.associated_learners
              }
              setCurrentUser(userDataSubset)
            } else {
              // Handle the case where there are no items in the response
              console.log('No user data found')
            }
          }
          setIsUserLoading(false)
        }
      } catch (error: any) {
        // Handle error
        if (error.message && error.message == 'Sorry, Your account is not active, please contact your account administrator') {
          const keysToRemove = ['userEmail', 'msalB2CId', 'UserId', 'AccountId', 'msalClientId', 'msalClientUrl', 'apiUrl']

          // issue in msal, sometimes this below status is not cleared so manually clearing the msal.interaction.status
          const itemKey = 'msal.interaction.status'
          if (sessionStorage.getItem(itemKey)) {
            sessionStorage.removeItem(itemKey)
          }

          keysToRemove.forEach((key) => localStorage.removeItem(key))
          // alert('Sorry, Your account is not active, please contact your account administrator');
          instance.logout()
        }
      } finally {
        isFetching.current = false
      }
    }
    fetchData()
  }, [dataProvider, userId])

  return <UserContext.Provider value={{ currentUser, isUserLoading }}>{children}</UserContext.Provider>
}
