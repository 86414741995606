import React, { FC, useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useFormNotify } from '@src/components/formNotify/useFormNotify'
import { LxLoadingSpinner } from '@src/components/loader/loadingSpinner'
import { LxSelect } from '@src/components/select/select'
import { RobotB } from '@src/logic/contexts/Robots/RobotsBackend.type'
import { RobotF } from '@src/logic/contexts/Robots/RobotsFrontend.type'
import { useDataProvider } from 'react-admin'
import { useForm, Controller } from 'react-hook-form'
import * as yup from 'yup'
import styles from './CombinedForm.module.scss'
import { cn } from '@src/utils/cn.ts'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';


export type AssignRobotFormType = {
    robotId: string
  accountId: string
}

// Default values for the form
export const defaultAssignRobotFormValues = (accountId: string): AssignRobotFormType => {
  return {
    robotId: '',
    accountId: accountId,
  }
}

// Validation schema for the form
export const AssignRobotFormValidation = yupResolver(
  yup
    .object()
    .shape({
        robotId: yup.string().required('Robot is required'),
      accountId: yup.string().required('Account is required'),
    })
    .required()
)

// Function to parse form data to backend format, This will be called by SubscriptionCreate
export const parseAssignRobotFormToBackend = (formData: AssignRobotFormType) => ({
    robotId: formData.robotId,
  customer_acc_id: formData.accountId,
})

// Component props
type Props = {
  onSubmit: (formData: AssignRobotFormType) => void
  onCancel: () => void
  accountId: string
}

const AssignRobot: FC<Props> = ({ onSubmit, accountId, onCancel }) => {

   
  const dataProvider = useDataProvider()
  const [robotData, setRobotData] = useState<RobotF[] | null>(null)
  const [isRobotDataLoading, setIsRobotDataLoading] = useState(true)
  
   const {
    control,
    handleSubmit,
    formState: { errors, submitCount, isValid },
  } = useForm({
    defaultValues: defaultAssignRobotFormValues(accountId),
    resolver: AssignRobotFormValidation,
  })


  useFormNotify(errors, submitCount, isValid)

  useEffect(() => {
    if (accountId) {
      setIsRobotDataLoading(true)
       dataProvider
        .getList('Robot', {
          pagination: { page: 0, perPage: 100 }, // Fixed 'perPage' typo
          sort: { field: 'id', order: 'ASC' },
          filter: { account_name: null },
        })
        .then((response) => {
          const dataSubset = response.data.map((robot: RobotB) => ({
            label: robot.serial,
            value: robot.id,
          }))
        
          setRobotData(dataSubset as RobotF[])
        })
        .catch(() => setRobotData(null))
        .finally(() => setIsRobotDataLoading(false))
    }
  }, [accountId])

  
  if (isRobotDataLoading) {
    return <LxLoadingSpinner />
  }

  if (!robotData || robotData.length === 0) {
    return (
      <Dialog open={true} onClose={onCancel}>
        <DialogTitle>No Unassigned Robots</DialogTitle>
        <DialogContent>
          <DialogContentText>
            No robot is currently available. You can declare and assign a robot later on the Robots page.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color="primary">
            ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  }



  return (
    <form className={styles.subscriptionForm} onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="robotId"
        control={control}
        render={({ field, fieldState }) => (
          <LxSelect
            value={field.value}
            hasError={!!fieldState.error}
            shrinked
            placeholder={"Select Robot"}
            onChange={field.onChange}
            options={robotData}
          />
        )}
      />

      <div className={styles.formActions}>
        <div></div>
        <div
          className={cn('lxActionButton lxActionButtonFilled lxActionButtonDefaultSize')}
          onClick={handleSubmit(onSubmit)}
        >
          <span>Assign Robot</span>
        </div>
      </div>
    </form>
  )
}

export default AssignRobot
