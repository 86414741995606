import { SubscriptionForm, SubscriptionFormType, parseSubscriptionFormToBackend } from './SubscriptionForm.tsx'
import { useNavigate } from 'react-router-dom'
import { useDataProvider, useNotify } from 'react-admin'
import { LxIcon } from '@components/icon/Icon.tsx'
import styles from './SubscriptionForm.module.scss'
import { SessionPlanIcon } from '@icons/index.ts'
import { useSubmitLoader } from '@src/logic/contexts/utils/SubmitContext.tsx'
import { useAccountsList } from '@src/logic/contexts/Accounts/AccountsListsContext.tsx'

export const SubscriptionCreate = () => {
  const navigate = useNavigate()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const { isFormSubmitting, setIsFormSubmitting } = useSubmitLoader()

  const { accountList, isLoading: isAccountsLoading } = useAccountsList()

  const onSubmit = async (formData: SubscriptionFormType) => {
    if (isFormSubmitting) {
      return
    }

    setIsFormSubmitting(true)

    try {
      await dataProvider.create('Subscription', {
        data: parseSubscriptionFormToBackend(formData),
      })

      notify('Subscription created successfully', { type: 'success' })
      backToSubscriptions()
    } catch (error) {
      const errorMessage = error.message || 'Something went wrong, Please try again after sometime'
      notify(errorMessage, { type: 'error' })
    } finally {
      setIsFormSubmitting(false)
    }
  }

  const backToSubscriptions = () => {
    navigate('/Subscriptions') // this will navigate to the previous page
  }

  return (
    <div>
      <div>
        <div class={styles.ProfileHeader}>
          <LxIcon icon={SessionPlanIcon} sxStyles={{ height: '80px', width: '80px' }}></LxIcon>
          <h3>Add New Subscription</h3>
        </div>
      </div>
      <SubscriptionForm
        onSubmit={onSubmit}
        onCancel={backToSubscriptions}
        isSubmitting={isFormSubmitting}
        accountList={accountList}
        isAccountsLoading={isAccountsLoading}
      ></SubscriptionForm>
    </div>
  )
}

export default SubscriptionCreate
